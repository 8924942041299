import React, { Component } from "react"
import styled from "styled-components"

import { device } from '../css/CSSMediaQueries.js'

const Tiles = styled.div`
  display: grid;
  justify-content: left;
  grid-template-columns: ${(props) => {
    let { cols } = props;
    if (typeof(cols) === 'undefined' || cols === null) return '1fr 1fr 1fr';
    let str = '';
    for (let i = 0 ; i < cols; ++i) {
      if (i === cols-1) str += '1fr';
      else str += '1fr ';
    }
    return str;
  }};
  grid-gap: 30px;
  max-width: 1100px;

  @media ${device.laptop} {
    grid-template-columns: 1fr;
  }
  
  > div span, b {
    font-size: 18px;
    background-color: white;
  }
  line-height: 1.5;
`

const Tile = styled.div`
  border: 1px solid #ddd;
  background-color: white;
  /* max-width: 350px; */
  padding: 30px;
  > h4 {
    background-color: white;
    font-size: 30px;
    margin: 0px;
    line-height: 1;
    margin-bottom: 30px;
  }
`

class TextTiles extends Component {
  render() {
    const { tiles, cols } = this.props;
    const entries = [];
    tiles.forEach((entry) => {
      const { title, content } = entry;
      entries.push(
        <Tile>
          <h4>{title}</h4>
          <span>{content}</span>
        </Tile>
      )
    });
    return (
      <Tiles cols={cols}>
        {entries}
      </Tiles>
    )
  }
}

export default TextTiles